/* Style for "No" button */
.custom-no-button-class {
    color: #fff; /* Text color */
    background-color: #dc3545; /* Background color */
    border: 1px solid #dc3545; /* Border color */
  }
  
  /* Style for "Yes" button */
  .custom-yes-button-class {
    color: #fff; /* Text color */
    background-color: #28a745; /* Background color */
    border: 1px solid #28a745; /* Border color */
  }
  