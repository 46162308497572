.audio{
    margin: auto;
    margin-top: 4rem;
}

.css-15wwp11-MuiTableHead-root{
    width: 100% !important;
     overflow-x: auto !important;
     min-width: 800px !important;
    overflow: scroll !important;
    top: 0 !important;
    background: white !important;
    z-index: 1 !important;
    position: sticky !important;
    overflow-y: auto;
}

.css-dac6fd-MuiTableContainer-root{
    height: 600px !important
}

 /* for table header title sticky in live */
.css-1wbz3t9{
    display: table-header-group;
    position: sticky !important;
    top: 0 !important;
    overflow: scroll !important;
    overflow-y: auto !important;
    background: white !important;
}

/* for for table header title sticky in live */
.css-14ad8xz{
    width: 100% !important;
    overflow-x: auto !important;
    min-width: 800px !important;
    height: 600px !important;
}

.css-1byr0tz{
    padding-top: 60px
}

/* header css */
/* dev */
.css-10tu03f{
    font-weight: 600 !important;
    font-size: 0.875rem !important;
    width: fit-content !important;
    text-align: center !important;
}

.css-717039{
    font-weight: 600 !important;
    font-size: 0.875rem !important;
    width: fit-content !important;
    text-align: center !important;
}
/* local */
.css-1wx711i-MuiTypography-root{
    font-weight: 600 !important;
    font-size: 0.875rem !important;
    width: fit-content !important;
    text-align: center !important;
}

/* row css */

.css-1385kjj-MuiTableCell-root{
    font-size: 0.875rem !important;
    padding: 0;
}
.css-1kc0mi8-MuiTypography-root{
    font-size: 0.875rem !important;
}

.css-1yrymlm-MuiTypography-root{
    font-size: 0.875rem !important;
}

.css-1siwd0x{
    font-size: 0.875rem !important;
    padding: 0; 
}

.css-8epd4k{
    font-size: 0.875rem !important;
    padding: 0; 
}

/* dev */
.css-ap3x5k{
    font-size: 0.875rem !important;
}
.css-12diqdm{
    font-size: 0.875rem !important;
}

.css-hekjhc{
    font-size: 0.875rem !important;
}

.css-15881ev{
    font-size: 0.875rem !important;
}

.css-k36ljx{
    font-size: 0.875rem !important;
}


.details-using-ref-id{
    text-decoration: none;
    color:  #2196f3;
}


/* .css-79ws1d-MuiModal-root {
    z-index: 1;
} */

.modalDatePicker{
    z-index: 1;
}

.datePicker{
    justify-content: space-around !important;
    text-align: center !important;
    margin: 3%;
    height: 20%;
}


/* .css-1byr0tz{
  overflow: hidden;
} */

.css-11u01ml-MuiTypography-root {
    line-height: 1.5rem;
    font-size: 0.875rem;
    font-family: Public Sans, sans-serif;
    font-weight: 600;
    /* display: table-cell; */
    vertical-align: inherit;
    /* border-bottom: 1px solid rgba(241, 243, 244, 1); */
    /* text-align: center; */
    /* padding: 6px 16px; */
    color: #212B36;
}

.css-1385kjj-MuiTableCell-root{
    /* font-size: 0.875rem !important; */
    /* padding: 0; */
    line-height: 1.5714285714285714;
    font-size: 0.875rem;
    font-family: Public Sans, sans-serif;
    font-weight: 400;
    display: table-cell;
    vertical-align: inherit;
    border-bottom: 1px solid rgba(241, 243, 244, 1);
    text-align: left;
    /* padding: 6px 16px; */
    color: #212B36;
    text-align: center;
}

.css-d3ri6l-MuiStack-root{
    margin-bottom: 0px;
}

.typo-bold-book-order{
    font-weight: bold;
}

.booking-ref-ids{
    color: #2196f3;
    cursor: pointer;
}

.order-detail-pet-detail{
    color: #2196f3;
    cursor: pointer;
    margin-left: 4px;
    text-decoration: underline !important;
}

.journey-track{
    display: flex;
    color: #884AED;
    margin-top: 3% !important;
}

.journey-sub-title{
    color: #787575;
    font-size: 13px;
    font-weight: 500;
    line-height: 16.5px !important;
    display: block !important;
}

.right-journey-track{
    position: absolute !important;
    top: -5px !important;
    left: 20px !important;
    background-color: white !important;
    color: #000000 !important;
    font-weight: 400;
}

.grooming-performed-by{
    font-weight: 700 !important;
    font-size: 12px !important;
     line-height: 18px !important;
     color: #000000;
}